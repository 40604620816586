// ドロワーメニュー開く
window.addEventListener(
  'load',
  () => {
    const body = document.body;
    document.querySelectorAll('.js-drawerOpen').forEach(
      (trigger) =>
        (trigger.onclick = () => {
          body.dataset.menuOpen = body.dataset.menuOpen == 'true' ? 'false' : 'true';
        }),
    );
  },
  false,
);
