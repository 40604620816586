// ポリフィル
import 'pollyfill.es6';

import Rails from '@rails/ujs';
Rails.start();

// EventEmitter
import EventEmitter from 'eventemitter3';
window.emitter = new EventEmitter();

import WebpackerReact from 'webpacker-react';
// アラート
import Alert from 'products/components/alert';
WebpackerReact.setup({ Alert });

// サンプル
import Sample from 'products/components/sample';
WebpackerReact.setup({ Sample });

// ログイン導入
import Gateway from 'products/components/gateway';
WebpackerReact.setup({ Gateway });

// レッスン動画
import Player from 'products/components/player';
WebpackerReact.setup({ Player });

// ドロワーメニュー
import 'products/scripts/drawer.es6';

// ページ内スクロール
import 'products/scripts/scroll.es6';

// ページ遷移後スクロール
import 'products/scripts/transited_scroll.es6';
